import { useState } from "react";
import { Drawer, Button, Group, ActionIcon } from "@mantine/core";
import { Menu2, Logout } from "tabler-icons-react";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import {
  Refresh,
  FileSearch,
  Check,
  ArrowBigRight,
  ListCheck,
  Settings,
  Report,
  CirclePlus,
} from "tabler-icons-react";

export default function X() {
  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title={<></>}
        // padding="xl"
        // size="xl"
        transition="rotate-left"
        transitionDuration={250}
        transitionTimingFunction="ease"
      >
        <div className="logoContainer">
          <div className="LogoText1">Awe</div>
          <div className="LogoText2">So</div>
          <div className="LogoText3">Me</div>
        </div>
        <br />
        <br />

        {/* <Button
          fullWidth
          variant="subtle"
          size="xl"
          onClick={() => {
            window.location.href = "#/main";
            setOpened(true);
          }}
        >
          Home
        </Button> */}
        <br />

        <div
          className="nav_MenuItem_Btn"
          onClick={() => {
            window.location.href = "#/ticket";
            setOpened(true);
          }}
        >
          <ListCheck /> My Ticket
        </div>
        <br />
        <div
          className="nav_MenuItem_Btn"
          onClick={() => {
            window.location.href = "#/request";
            setOpened(true);
          }}
        >
          <ListCheck /> My Request
        </div>
        <br />
        <div
          className="nav_MenuItem_Btn"
          onClick={() => {
            window.location.href = "#/create";
            setOpened(true);
          }}
        >
          <CirclePlus />
          Create Ticket
        </div>
        <br />

        <div
          className="nav_MenuItem_Btn"
          onClick={() => {
            window.location.href = "#/summary";
            setOpened(true);
          }}
        >
          <Report /> Summary
        </div>
        <br />
        <div
          className="nav_MenuItem_Btn"
          onClick={() => {
            window.location.href = "#/setting";
            setOpened(true);
          }}
        >
          <Settings /> Setting
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="flexCol_center">
          <Button
            size="xl"
            variant="subtle"
            color="red"
            onClick={() => {
              signOut(authentication);
              setTimeout(() => {
                window.location.href = "/";
                alert("Signed Out Successully!");
              }, 1000);
            }}
          >
            <Logout />
            Logout
          </Button>
        </div>

        <br />
        <br />
        <div className="versionContainer">{`ver update: ${localStorage.getItem(
          "ver"
        )}`}</div>

        <br />
        <br />
      </Drawer>

      <Group position="left">
        <div className="nav_OpenMenu_Btn" onClick={() => setOpened(true)}>
          <Menu2 /> MENU
        </div>

        {/* <ActionIcon
          color="yellow"
          size="xl"
          variant="filled"
          onClick={() => setOpened(true)}
        >
          <Menu2 />
        </ActionIcon> */}
      </Group>
    </>
  );
}
