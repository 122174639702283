import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";

import { Menu, Divider, Text } from "@mantine/core";
import {
  Settings,
  Search,
  Photo,
  MessageCircle,
  Trash,
} from "tabler-icons-react";

import { Logout } from "tabler-icons-react";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import { UserContext } from "../UserContext";

export default function X() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  return (
    <>
      <div className="nav_Text">
        {/* {console.log("contextValue", contextValue)} */}
        <div className="nav_Text_Title">{` ${contextValue?.bd_UserInfo?.u_name} , ${contextValue?.bd_UserInfo?.u_role}`}</div>

        <div>{` ${contextValue?.bd_UserInfo?.u_outlets}`}</div>
      </div>
      <Menu
        control={
          <div style={{ display: "absolute" }}>
            <img
              className="nav_profilPic"
              src={`${firebase_userInfo?.photoURL}`}
            />
          </div>
        }
      >
        <Menu.Item
          color="red"
          icon={<Logout size={20} />}
          onClick={() => {
            signOut(authentication);
            setTimeout(() => {
              window.location.href = "/";
              alert("Signed Out Successully!");
            }, 1000);
          }}
        >
          Logout
        </Menu.Item>
      </Menu>
    </>
  );
}
